<template>
  <div class="col-xxl-12 px-0">
    <div
      class="card card-custom card-stretch gutter-b"
      :class="projectTemplateErrors != null ? 'bordered-card-danger' : 'bordered-card-primary'"
    >
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            {{ $t('NAVIGATION.CLIENT_MENU.TEMPLATES') }}
          </h3>
        </div>
      </div>
      <div class="card-body">
        <ProjectTemplatesTable
          :templateList="projectTemplateList"
          :loadingComplete="loadingComplete"
          :error="projectTemplateErrors"
          type="client"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations
} from "vuex";

import { SET_BREADCRUMB } from "@/core/services/store/system/breadcrumbs.module";

import PageLoader from "@/core/classes/PageLoader.js";
import ProjectTemplatesTable from "@/view/components/tables/ProjectTemplatesTable";

export default {
  name: "ViewProjectTemplates",

  data() {
    return {
      pageLoader: new PageLoader(1),
      refreshTimer: null
    }
  },

  components: {
    ProjectTemplatesTable
  },

  mounted() {
    this.cleanup();
    let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `${this.$t("NAVIGATION.ROOT_MENU.CLIENTS")}`, route: protocol + 'admin.' + process.env.VUE_APP_API_ROOT + "/#/clients/view" },
      {
        title: this.clientInfo.name,
        route: "/client/projects"
      },
      { title: `${this.$t("NAVIGATION.CLIENT_MENU.TEMPLATES")}` }
    ]);
    this.fetchProjectTemplates();
    this.refreshTimer = setInterval(() => {
      this.fetchProjectTemplates();
    }, 10000)
  },

  unmounted() {
    this.cleanup();
  },

  methods: {
    fetchProjectTemplates: function() {
      let payload = {
        route: `api/v1/clients/${this.clientInfo.id}/project_templates`,
        params: {
          paginate: false
        },
        relations: {
          language: 'with'
        }
      };
      this.$store.dispatch(GENERIC_RESOURCE, payload)
        .then((res) => {
          this.pageLoader.setCompleteComponent(1);
          this.setProjectTemplateList(res._embedded.project_templates);
        })
        .catch((error) => {
          this.setProjectTemplateErrors(error);
        })
    },
    cleanup: function() {
      this.setProjectTemplateList([]);
      this.setProjectTemplateErrors();
    },
    ...mapMutations({
      setProjectTemplateList: "setProjectTemplateList",
      setProjectTemplateErrors: "setProjectTemplateErrors"
    })
  },

  computed: {
    ...mapGetters([
      "clientInfo",
      "projectTemplateList",
      "projectTemplateErrors"
    ]),
    loadingComplete() {
      return this.pageLoader.getStatus();
    },
  }
}
</script>